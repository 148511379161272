@import "./styles/colors";
@import "./styles/common";

.app {
  height: 100%;
  overflow: auto;
  background: #fdfdfd 0% 0% no-repeat padding-box;
  opacity: 1;
}

pre {
  margin: 0;
  padding: 0;
}

.siteTileHover:hover .scoreBadge,
.tileHover:hover .scoreBadge {
  display: flex !important;
}
